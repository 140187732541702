body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  src: url(/static/media/Inter-Thin.95dcd983.woff2) format("woff2"),
       url(/static/media/Inter-Thin.993430a0.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  src: url(/static/media/Inter-ThinItalic.bab091cd.woff2) format("woff2"),
       url(/static/media/Inter-ThinItalic.03aaefea.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  src: url(/static/media/Inter-ExtraLight.3e86dd6b.woff2) format("woff2"),
       url(/static/media/Inter-ExtraLight.7410d537.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  src: url(/static/media/Inter-ExtraLightItalic.233a2dea.woff2) format("woff2"),
       url(/static/media/Inter-ExtraLightItalic.2ab0bd66.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  src: url(/static/media/Inter-Light.42922cef.woff2) format("woff2"),
       url(/static/media/Inter-Light.235041ef.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  src: url(/static/media/Inter-LightItalic.ba0b2a0c.woff2) format("woff2"),
       url(/static/media/Inter-LightItalic.7547f5c1.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  src: url(/static/media/Inter-Regular.bffaed79.woff2) format("woff2"),
       url(/static/media/Inter-Regular.aebfbb3c.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  src: url(/static/media/Inter-Italic.381444ec.woff2) format("woff2"),
       url(/static/media/Inter-Italic.35cf8109.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  src: url(/static/media/Inter-Medium.2e5e0884.woff2) format("woff2"),
       url(/static/media/Inter-Medium.c09fb389.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  src: url(/static/media/Inter-MediumItalic.7a7fd735.woff2) format("woff2"),
       url(/static/media/Inter-MediumItalic.ad6e093c.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  src: url(/static/media/Inter-SemiBold.cd04cacd.woff2) format("woff2"),
       url(/static/media/Inter-SemiBold.acc6eebd.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  src: url(/static/media/Inter-SemiBoldItalic.57262d35.woff2) format("woff2"),
       url(/static/media/Inter-SemiBoldItalic.569061c2.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  src: url(/static/media/Inter-Bold.34356f6b.woff2) format("woff2"),
       url(/static/media/Inter-Bold.61c493e3.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  src: url(/static/media/Inter-BoldItalic.57acb479.woff2) format("woff2"),
       url(/static/media/Inter-BoldItalic.6fbcf86a.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBold.a627e2ce.woff2) format("woff2"),
       url(/static/media/Inter-ExtraBold.0b03428a.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBoldItalic.b95f0df1.woff2) format("woff2"),
       url(/static/media/Inter-ExtraBoldItalic.137fcd98.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  src: url(/static/media/Inter-Black.c7a3bad1.woff2) format("woff2"),
       url(/static/media/Inter-Black.a4502329.woff) format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  src: url(/static/media/Inter-BlackItalic.d000d76e.woff2) format("woff2"),
       url(/static/media/Inter-BlackItalic.094751f4.woff) format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url(/static/media/Inter-upright.var.dc162a64.woff2) format("woff2 supports variations(gvar)"),
       url(/static/media/Inter-upright.var.dc162a64.woff2) format("woff2-variations"),
       url(/static/media/Inter-upright.var.dc162a64.woff2) format("woff2");
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url(/static/media/Inter-italic.var.74578302.woff2) format("woff2 supports variations(gvar)"),
       url(/static/media/Inter-italic.var.74578302.woff2) format("woff2-variations"),
       url(/static/media/Inter-italic.var.74578302.woff2) format("woff2");
}


/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  src: url(/static/media/Inter.var.6f521bf6.woff2) format("woff2-variations"),
       url(/static/media/Inter.var.6f521bf6.woff2) format("woff2");
}

*{ font-family: 'Inter', sans-serif; }
.formWrap{
  background:purple;
  color:#fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  height:100%;
  -webkit-align-items:center;
          align-items:center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding:5rem;
}
.formWrap form{
  width: 70%;
  z-index:20;
}
input[type="text"], textarea{
  padding:1.2rem;
  border:2px solid #fff;
  background:transparent;
  margin:4% 0;
  width:calc(100% - 2.4rem);
  color:#FFF;
  font-weight: 900;
  /* background-color: rgba(255,255,255,.3);
  transition: background-color .3s; */
}
/* input[type="text"]:focus, textarea:focus {
  background-color: rgba(255,255,255,1);

 } */
 .rdt input[type='text']{
    width:calc(25% - 2.4rem);
    display: block;
 }
 .rdt .rdtPicker{
  display: none;
}
.rdt.rdtOpen .rdtPicker{
  display: inline-block;
}
.rdt .rdtActive{
  border:1px solid;
} 
.rdt td{
  text-align:center;
}
.rdt .rdtDays table *{
  border:1px solid transparent;
}
.rdt .rdtDays table td:hover{
  border:1px solid #fff;
  cursor: pointer;
}
.rdtCounters .rdtCounter, .rdtCounters .rdtCounterSeparator{
  display: inline-block;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
}
.rdtCounters .rdtCounterSeparator{
  -webkit-transform:translateY(-100%);
          transform:translateY(-100%);
}
input[type="file"]{
  display:none;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color:#fff;
  font-style:italic;
  font-weight: 400;
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color:#fff;
  font-style:italic;
  font-weight: 400;
}
input::placeholder, textarea::placeholder {
  color:#fff;
  font-style:italic;
  font-weight: 400;
}
.inputHolder{
  margin: 2% 0;
  width:100%;
  
}
.inputHolder.image{
  overflow: hidden;
}
.inputHolder label{
  display:block;
}
.inputHolder.image strong{
  display:inline-block;
  border-bottom:2px solid transparent;
  margin-right: 10px;
}
.inputHolder.image strong:hover{
  cursor: pointer;
}
.inputHolder.image strong.active, .inputHolder.image strong:hover{
  border-bottom:2px solid #fff;
}
.inputHolder label.uploadImage{
  border:2px solid #fff;
  display:inline-block;
  padding:1.2rem;
}
.inputHolder label.uploadImage:hover{
  background: #FFF;
  color:purple;
  cursor: pointer;
}
.imageOptsWrap{
  padding:0;
  /* border:2px solid #fff; */
  background:transparent;
  margin:4% 0;
  width:200%;
  display: inline-block;
  transition:-webkit-transform .3s;
  transition:transform .3s;
  transition:transform .3s, -webkit-transform .3s;
  /* cursor: pointer; */
}
.imageOptsWrap.upload{
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}
.imageUpload, .giphySearch{
  width:50%;
  display:inline-block;
  vertical-align: text-top;
}
.giphySearch input{
  width: 75%;
}
.imageUpload{
  border:2px solid #fff;
  padding:2%;
  margin-right:2%;
  width:calc(44% - 4px);

}
.imagePick{
  /* width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
  position: relative;
  left: 0;
  top:0;
	z-index: -1; */
}
.imagePick + label {
  /* padding:1.2rem;
  border:2px solid #fff;
  background:transparent;
  margin:4% 0;
  width:50%;
  display: inline-block;
  cursor: pointer; */
}
.resultsHolder{
  display:none;
  width:50%;
  height: 300px;
  position: relative;
  overflow-y:scroll;
  background: rgba(255, 255, 255, .3);
  padding: 2%;
}
.resultsHolder ul{
  list-style: none;
  margin:0;
  padding:0;
}
.resultsHolder ul li{
  /* display: inline-block; */
  float: left;
  height: 100px;
  width: 150px;
  overflow: hidden;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 1% 1% 0;
  border:1px solid transparent;
}
.resultsHolder ul li:hover{
  cursor: pointer;
  border:1px solid #fff;
}
.resultsHolder.active{
  display: inline-block;
}
input{
  display: inline-block;
}
#root{
  height: 100%;
}
.formWrap button.createBtn, .countdown-wrapper a.createNew button{
  padding:1.2rem;
  border:2px solid #fff;
  background:transparent;
  margin:4% 0;
  /* width:50%; */
  display: block;
  color:#FFF;
}
a, a:visited{
  text-decoration: none;
  color:purple;
}
.formWrap button.createBtn:hover, .countdown-wrapper a.createNew button:hover{
  cursor: pointer;
  color:purple;
  background:#fff;
}
.calendarLinks a{
  display: inline-block;
  margin-right: 2%;
  color:purple;
}
.countdown-wrapper{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.countdown-wrapper a.createNew{
  position: absolute;
  right:20px;
  top:20px;
  width:auto;
}
.countdown-wrapper .imgWrap{
  width:100%;
  position: absolute;
  z-index: -10;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.countdown-wrapper .imgWrap img{
  width: 100%;
}
.countdown-wrapper .imgWrap .screen{
  background: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
}
.countdown-wrapper .header{
  color:#FFF;
  font-size:2em;
  text-align: center;
  margin-top: 12.5%;
}
.countdown-wrapper h3 span{
  display:inline-block;
  margin-right: 3vw;
}
.countdown-wrapper h3 span:last-of-type{
  margin-right: 0;
}
.countdown-wrapper h3 .hidden{
  display:none;
}
.countdown-wrapper .detailWrap{
  background: #fff;
  padding: 1em 2.5em 2.5em 2.5em;
  bottom: 5%;
  left: 5%; 
  width: calc(90% - 5em);
  position: absolute;
  text-align: center;
}
.countdown-wrapper .detailWrap a{
  padding:.5vh;
}
.countdown-wrapper .detailWrap a:hover{
  background: purple;
  color:#fff;
}
.tilesWrap{
  display:grid;
  grid: repeat(50, 2vh) / repeat(50, 2vw);
  position: fixed;
  top:0px;
  opacity: .4;
  z-index:0;
}
.tilesWrap span{
  display: inline-block;
  background:#fff;
  width: 20px;
  height: 20px;
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  box-shadow: 0px 0px 4px rgba(0,0,0,.3);
}
@-webkit-keyframes spin {
  0%{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-top:0px;
  }
  100% {
    -webkit-transform: rotate(3600deg);
            transform: rotate(3600deg);
    margin-top:-10px;
  }
}
@keyframes spin {
  0%{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-top:0px;
  }
  100% {
    -webkit-transform: rotate(3600deg);
            transform: rotate(3600deg);
    margin-top:-10px;
  }
}


@media screen and (max-width: 600px){
  .countdown-wrapper h3{
    font-size: 4vh;
    padding:0 2vh;
  }
  .countdown-wrapper h4{
    font-size: 3.6vh;
  }
  .countdown-wrapper .detailWrap {
    padding: 1em 2.5em 1em 2.5em;
    bottom: 0;
    left: 0;
    width: calc(100% - 5em);
  }
  .countdown-wrapper a.createNew button {
    padding:.6rem;
  }
}
