@import url('./fonts/inter.css');
*{ font-family: 'Inter', sans-serif; }
.formWrap{
  background:purple;
  color:#fff;
  display: flex;
  flex-direction:column;
  height:100%;
  align-items:center;
  justify-content: space-around;
  padding:5rem;
}
.formWrap form{
  width: 70%;
  z-index:20;
}
input[type="text"], textarea{
  padding:1.2rem;
  border:2px solid #fff;
  background:transparent;
  margin:4% 0;
  width:calc(100% - 2.4rem);
  color:#FFF;
  font-weight: 900;
  /* background-color: rgba(255,255,255,.3);
  transition: background-color .3s; */
}
/* input[type="text"]:focus, textarea:focus {
  background-color: rgba(255,255,255,1);

 } */
 .rdt input[type='text']{
    width:calc(25% - 2.4rem);
    display: block;
 }
 .rdt .rdtPicker{
  display: none;
}
.rdt.rdtOpen .rdtPicker{
  display: inline-block;
}
.rdt .rdtActive{
  border:1px solid;
} 
.rdt td{
  text-align:center;
}
.rdt .rdtDays table *{
  border:1px solid transparent;
}
.rdt .rdtDays table td:hover{
  border:1px solid #fff;
  cursor: pointer;
}
.rdtCounters .rdtCounter, .rdtCounters .rdtCounterSeparator{
  display: inline-block;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
}
.rdtCounters .rdtCounterSeparator{
  transform:translateY(-100%);
}
input[type="file"]{
  display:none;
}
input::placeholder, textarea::placeholder {
  color:#fff;
  font-style:italic;
  font-weight: 400;
}
.inputHolder{
  margin: 2% 0;
  width:100%;
  
}
.inputHolder.image{
  overflow: hidden;
}
.inputHolder label{
  display:block;
}
.inputHolder.image strong{
  display:inline-block;
  border-bottom:2px solid transparent;
  margin-right: 10px;
}
.inputHolder.image strong:hover{
  cursor: pointer;
}
.inputHolder.image strong.active, .inputHolder.image strong:hover{
  border-bottom:2px solid #fff;
}
.inputHolder label.uploadImage{
  border:2px solid #fff;
  display:inline-block;
  padding:1.2rem;
}
.inputHolder label.uploadImage:hover{
  background: #FFF;
  color:purple;
  cursor: pointer;
}
.imageOptsWrap{
  padding:0;
  /* border:2px solid #fff; */
  background:transparent;
  margin:4% 0;
  width:200%;
  display: inline-block;
  transition:transform .3s;
  /* cursor: pointer; */
}
.imageOptsWrap.upload{
  transform:translateX(-50%);
}
.imageUpload, .giphySearch{
  width:50%;
  display:inline-block;
  vertical-align: text-top;
}
.giphySearch input{
  width: 75%;
}
.imageUpload{
  border:2px solid #fff;
  padding:2%;
  margin-right:2%;
  width:calc(44% - 4px);

}
.imagePick{
  /* width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
  position: relative;
  left: 0;
  top:0;
	z-index: -1; */
}
.imagePick + label {
  /* padding:1.2rem;
  border:2px solid #fff;
  background:transparent;
  margin:4% 0;
  width:50%;
  display: inline-block;
  cursor: pointer; */
}
.resultsHolder{
  display:none;
  width:50%;
  height: 300px;
  position: relative;
  overflow-y:scroll;
  background: rgba(255, 255, 255, .3);
  padding: 2%;
}
.resultsHolder ul{
  list-style: none;
  margin:0;
  padding:0;
}
.resultsHolder ul li{
  /* display: inline-block; */
  float: left;
  height: 100px;
  width: 150px;
  overflow: hidden;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 1% 1% 0;
  border:1px solid transparent;
}
.resultsHolder ul li:hover{
  cursor: pointer;
  border:1px solid #fff;
}
.resultsHolder.active{
  display: inline-block;
}
input{
  display: inline-block;
}
#root{
  height: 100%;
}
.formWrap button.createBtn, .countdown-wrapper a.createNew button{
  padding:1.2rem;
  border:2px solid #fff;
  background:transparent;
  margin:4% 0;
  /* width:50%; */
  display: block;
  color:#FFF;
}
a, a:visited{
  text-decoration: none;
  color:purple;
}
.formWrap button.createBtn:hover, .countdown-wrapper a.createNew button:hover{
  cursor: pointer;
  color:purple;
  background:#fff;
}
.calendarLinks a{
  display: inline-block;
  margin-right: 2%;
  color:purple;
}
.countdown-wrapper{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.countdown-wrapper a.createNew{
  position: absolute;
  right:20px;
  top:20px;
  width:auto;
}
.countdown-wrapper .imgWrap{
  width:100%;
  position: absolute;
  z-index: -10;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.countdown-wrapper .imgWrap img{
  width: 100%;
}
.countdown-wrapper .imgWrap .screen{
  background: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
}
.countdown-wrapper .header{
  color:#FFF;
  font-size:2em;
  text-align: center;
  margin-top: 12.5%;
}
.countdown-wrapper h3 span{
  display:inline-block;
  margin-right: 3vw;
}
.countdown-wrapper h3 span:last-of-type{
  margin-right: 0;
}
.countdown-wrapper h3 .hidden{
  display:none;
}
.countdown-wrapper .detailWrap{
  background: #fff;
  padding: 1em 2.5em 2.5em 2.5em;
  bottom: 5%;
  left: 5%; 
  width: calc(90% - 5em);
  position: absolute;
  text-align: center;
}
.countdown-wrapper .detailWrap a{
  padding:.5vh;
}
.countdown-wrapper .detailWrap a:hover{
  background: purple;
  color:#fff;
}
.tilesWrap{
  display:grid;
  grid: repeat(50, 2vh) / repeat(50, 2vw);
  position: fixed;
  top:0px;
  opacity: .4;
  z-index:0;
}
.tilesWrap span{
  display: inline-block;
  background:#fff;
  width: 20px;
  height: 20px;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  box-shadow: 0px 0px 4px rgba(0,0,0,.3);
}
@keyframes spin {
  0%{
    transform: rotate(0deg);
    margin-top:0px;
  }
  100% {
    transform: rotate(3600deg);
    margin-top:-10px;
  }
}


@media screen and (max-width: 600px){
  .countdown-wrapper h3{
    font-size: 4vh;
    padding:0 2vh;
  }
  .countdown-wrapper h4{
    font-size: 3.6vh;
  }
  .countdown-wrapper .detailWrap {
    padding: 1em 2.5em 1em 2.5em;
    bottom: 0;
    left: 0;
    width: calc(100% - 5em);
  }
  .countdown-wrapper a.createNew button {
    padding:.6rem;
  }
}